import { useState } from "react";
import { Button } from "@nextui-org/react";
import { TechsDisplayer } from "./TechsDisplayer";
import { ProjectsDisplayer } from "./ProjectsDisplayer";
import {Divider} from "@nextui-org/divider";


export function Home() {
  // handlers
  const [dark, setDark] = useState(true);
  const darkModeHandler = () => {
    setDark(!dark);
    document.body.classList.toggle("dark");
  };
    
  return (
    <div className="flex flex-col bg-zinc-50 dark:bg-neutral-950 min-h-screen transition-all duration-800 ease-in-out">

      {/* Dark mode button */}
      <Button variant="flat" onClick={darkModeHandler} 
      // no focus since this introduces a glitch when using mobile.
      className="text-md font-bold fixed top-4 right-4 p-3 rounded dark:text-neutral-300 focus:outline-none focus:ring-0">
          {dark ? '🌙' : '🔦'}
      </Button>


      {/* HEADER and Notes */}
      <div className="p-8">
        <p className="select-none text-2xl text-gray-900 dark:text-neutral-100">
            Guido Enrique's
        </p>

        <p className="text-md font-bold select-none text-zinc-800 dark:text-neutral-400">
            Show<a className="line-through text-neutral-200 dark:text-zinc-500">time</a>case!
        </p>

        <p className="text-xs select-none mt-4 p-1 text-gray-900 dark:text-neutral-400">
        ⚠️ some projects listed here have private repos for obvious reasons.
        </p>
      </div>

     {/* Flex container for ProjectsDisplayer and TechsDisplayer */}
      <div className="flex flex-col md:flex-row -mt-24 p-16 md:space-x-16 space-y-8 md:space-y-0">
        <div>
          <TechsDisplayer />
        </div>
        <div>
          <ProjectsDisplayer />  
        </div>
      </div>
    </div>
  );
}
